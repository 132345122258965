body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.provider-button {
  min-width: 300px;
  margin: 8px;
}

.provider-buttons {
  max-width: 500px;
  margin: auto;
}

.message-button {
  float: right;
}

table.note-table {
  width: 100%;
}

.welcome-buttons a{
  margin: 8px;
}

.navbar .navbar-item.mobile {
  text-align: center;
}

.center { 
  text-align: center;
}

.button-icon {
  padding: 0  0  0 6px;
}

.card-footer {
  padding: 8px 0 0 0;
}

.pull-right {
  float: right
}

.pull-left {
  float: left
}

.flex {
  flex: 1
}

.flex-2 {
  flex: 2
}

.level-left .level-item {
  justify-content: flex-start;
}

.level-right .level-item {
  justify-content: flex-end;
}

.level-item div{
  padding: 2px 4px;
}

.icon {
  cursor: pointer;
}

td .content {
  max-width: 200px;
}